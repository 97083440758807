<template>
  <div id="async-paged-table">
    <div>
      <slot name="header-row" />
    </div>
    <div class="controls">
      <div class="search">
        <el-input
          id="search-input"
          v-model="searchValue"
          placeholder="Search"
          size="small"
          clearable
          @keyup.enter.native="performSearch"
          @clear="resetSearch"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="performSearch"
          />
        </el-input>
        <slot name="controls" />
      </div>
      <div class="page-size-select">
        <el-select
          v-model="pageSize"
          size="small"
          @change="handlePageSizeChange"
        >
          <el-option label="25 per page" :value="25" />
          <el-option label="50 per page" :value="50" />
          <el-option label="100 per page" :value="100" />
          <el-option label="250 per page" :value="250" />
          <el-option label="500 per page" :value="500" />
        </el-select>
      </div>
    </div>

    <div class="table">
      <el-row>
        <el-table
          v-loading="loading"
          :data="data"
          v-bind="tableAttrs"
          @selection-change="$emit('selection-change', $event)"
        >
          <!-- render table columns -->
          <slot />
        </el-table>
      </el-row>

      <el-row>
        <el-pagination
          :page-size="pageSize"
          :current-page="currentPage"
          :total="total"
          layout="prev, pager, next, total"
          class="pagination"
          background
          @current-change="handlePageChange"
        />
      </el-row>
    </div>
  </div>
</template>

<script>
/**
 * Wrapper for ElementUI table with async pagination, search,
 * and page select controls.
 *
 * The parent component should handle the `fetch` event and set
 * the `data` and `total` props accordingly.
 *
 * `tableAttrs` prop supports all element table attributes:
 * https://element.eleme.io/#/en-US/component/table#table-attributes
 */
export default {
  name: 'AsyncPagedTable',
  props: {
    data: {
      type: Array,
      default: () => ([])
    },
    total: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    tableAttrs: {
      type: Object,
      default: () => ({
        showHeader: true,
        border: true,
        stripe: true,
        size: 'medium'
      })
    },
    persistFilters: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      searchValue: '',
      searchValueCopy: '',
      searchActive: false,
      currentPage: 1,
      pageSize: 25
    }
  },
  created () {
    if (!this.data.length) {
      // restore filters from route query
      if (this.persistFilters) {
        const query = this.$route.query
        const keys = Object.keys(query)
        for (const key of keys) {
          if (key === 'page_size') {
            this.pageSize = parseInt(query[key])
          } else if (key === 'page') {
            this.currentPage = parseInt(query[key])
          } else if (key === 'search') {
            this.searchValueCopy = this.searchValue = query[key]
            this.searchActive = true
          }
        }
      }
      this.fetchPage()
    }
  },
  methods: {
    /**
     * Emit event to fetch a single page of data from the api.
     */
    async fetchPage () {
      const params = {
        page_size: this.pageSize,
        page: this.currentPage
      }
      if (this.searchActive) {
        params.search = this.searchValue
      }

      // save search filters as route query
      if (this.persistFilters) {
        this.$router.replace({ path: this.$route.path, query: params }).catch(err => err)
      }

      this.$emit('fetch', params)
    },
    /**
     * Filter api results by the search value.
     */
    performSearch () {
      this.searchValueCopy = this.searchValue
      this.searchActive = this.searchValue !== ''
      this.currentPage = 1
      this.fetchPage()
    },
    /**
     * Reset the keyword search filter.
     */
    resetSearch () {
      this.searchValue = ''
      this.performSearch()
    },
    /**
     * onChange event handler for user changing the page.
     *
     * @param {Number} selectedPage - selected page
     */
    handlePageChange (selectedPage) {
      // Ensure the user hasn't changed the search value
      if (this.searchValue !== this.searchValueCopy) {
        this.searchValue = this.searchValueCopy
      }
      this.currentPage = selectedPage
      this.fetchPage()
    },
    /**
     * onChange event handler for page size select input.
     */
    handlePageSizeChange () {
      this.currentPage = 1
      this.fetchPage()
    }
  }
}
</script>

<style>
#async-paged-table .el-table th, #async-paged-table .el-table td {
  padding: 3px 0;
}
#async-paged-table .el-table th {
  background-color: #ebeef5;
}
</style>

<style scoped>
#async-paged-table, .table {
  margin-top: 1em;
}
.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search {
  display: flex;
  align-items: center;
}
#search-reset {
  padding-left: 10px;
}
.pagination {
  text-align: right;
  margin-top: 1em;
  margin-bottom: 1em;
}
</style>
