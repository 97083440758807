<template>
  <el-date-picker
    :value="dates"
    type="daterange"
    range-separator="To"
    start-placeholder="Start date"
    end-placeholder="End date"
    :picker-options="pickerOptions"
    :clearable="false"
    @input="$emit('date-change', $event)"
  />
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateRangePicker',
  props: {
    dates: {
      type: Array,
      required: true
    },
    disableFuture: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const pickerOptions = {
      shortcuts: [
        {
          text: 'Last week',
          onClick (picker) {
            const start = moment().subtract(1, 'week').toDate()
            const end = moment().toDate()
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Last month',
          onClick (picker) {
            const start = moment().subtract(1, 'month').toDate()
            const end = moment().toDate()
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: 'Last 3 months',
          onClick (picker) {
            const start = moment().subtract(3, 'months').toDate()
            const end = moment().toDate()
            picker.$emit('pick', [start, end])
          }
        }
      ]
    }

    if (this.disableFuture) {
      pickerOptions.disabledDate = time => {
        return time.getTime() > Date.now()
      }
    }

    return { pickerOptions }
  }
}
</script>
