<template>
  <div id="offboarding-report">
    <el-row
      type="flex"
      justify="space-between"
      align="bottom"
    >
      <el-col :span="10" class="title">
        <h2>Cancelled Property Report</h2>
        <div>
          This report shows all properties which have a subscription that
          ends during the selected date range.
        </div>
      </el-col>
      <el-col :span="14" class="date-picker">
        <date-range-picker :dates="dateRange" @date-change="handleDateChange" />
        <el-select
          v-model="ordering"
          class="order-select"
          @change="fetchData()"
        >
          <el-option
            v-for="option in orderOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          />
        </el-select>
      </el-col>
    </el-row>
    <async-paged-table
      ref="table"
      :loading="loading"
      :data="sortedPropertyData"
      :total="total"
      :persist-filters="false"
      @fetch="fetchData"
    >
      <el-table-column label="Name">
        <template slot-scope="scope">
          <div class="link" @click="goToPropertyEdit(scope.row)">
            {{ scope.row.property.name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Company">
        <template slot-scope="scope">
          <div
            class="link"
            @click="goToCompanyOverview(scope.row)"
          >
            {{ scope.row.company.human_name }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Product/Feature">
        <template slot-scope="scope">
          <div v-for="subscription in scope.row.cancelled" :key="subscription.id">
            {{ getProductFeatureName(subscription) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Subscriptions Cancelled">
        <template slot-scope="scope">
          {{ scope.row.cancelled.length }}
        </template>
      </el-table-column>
    </async-paged-table>
  </div>
</template>

<script>
import AsyncPagedTable from '@/components/tables/AsyncPagedTable'
import DateRangePicker from '@/components/dates/DateRangePicker'

import CraigslistAPI from '@/services/api/craigslist'

export default {
  name: 'CancelledReport',
  components: {
    'async-paged-table': AsyncPagedTable,
    'date-range-picker': DateRangePicker
  },
  data () {
    return {
      loading: false,
      propertyData: [],
      total: 0,
      ordering: '-end_date',
      orderOptions: [
        { label: 'Ascending', value: 'end_date' },
        { label: 'Descending', value: '-end_date' }
      ],
      dateRange: [
        this.$moment().subtract(1, 'month').startOf('day').toDate(),
        this.$moment().endOf('day').toDate()
      ]
    }
  },
  computed: {
    /**
     * Record the subscription(s) being cancelled directly on each
     * property object so that we can more easily render it in the
     * table.
     *
     * Returns data sorted by end_date.
     */
    sortedPropertyData () {
      return this.propertyData.map(prop => {
        prop.cancelled = []
        for (const subscription of prop.property.product_subscriptions) {
          const endDate = this.$moment(subscription.end_date)
          if (endDate.isBetween(this.dateRange[0], this.dateRange[1], null, '[]')) {
            prop.cancelled.push(subscription)
          }
        }
        for (const subscription of prop.property.feature_subscriptions) {
          const endDate = this.$moment(subscription.end_date)
          if (endDate.isBetween(this.dateRange[0], this.dateRange[1], null, '[]')) {
            prop.cancelled.push(subscription)
          }
        }
        prop.cancelled.sort((a, b) => {
          return new Date(b.end_date) - new Date(a.end_date)
        })
        return prop
      })
    }
  },
  methods: {
    /**
     * Fetch all properties which have a subscription end
     * date that falls within the selected date range.
     */
    async fetchData (params) {
      try {
        this.loading = true

        // if date range or order has changed, we need to get the filters from the table itself
        if (!params && this.$refs.table) {
          params = {
            page: this.$refs.table.currentPage,
            page_size: this.$refs.table.pageSize
          }
          if (this.$refs.table.searchActive) {
            params.search = this.$refs.table.searchValue
          }
        }

        const response = await CraigslistAPI.properties.listPage({
          ...params,
          end_date_after: this.$moment(this.dateRange[0]).format('YYYY-MM-DD'),
          end_date_before: this.$moment(this.dateRange[1]).format('YYYY-MM-DD'),
          ordering: this.ordering
        })
        this.propertyData = response.data.map(prop => {
          prop.company = this.getCompany(prop)
          return prop
        })
        this.total = response.count
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * onChange handler for datepicker component.
     *
     * @param {(Array|null)} dates - array of date objects, or null if the date range was cleared
     */
    handleDateChange (dates) {
      this.dateRange = dates || []

      if (this.dateRange.length) {
        this.fetchData()
      }
    },
    /**
     * Given a property, return the company.
     *
     * @param {Object} property
     * @returns {Object}
     */
    getCompany (property) {
      return this.$store.getters.getCompanyById(property.property.company)
    },
    /**
     * Given a subscription, get the product/feature name.
     *
     * @param {Object} subscription
     * @returns {String}
     */
    getProductFeatureName (subscription) {
      const name = subscription.hasOwnProperty('product')
        ? subscription.product
        : subscription.feature
      return `${name} (${subscription.end_date})`
    },
    /**
     * Navigate user to the PropertyEdit component.
     *
     * @param {Object} row
     */
    goToPropertyEdit (row) {
      const params = { cid: row.company.id, id: row.property.id }
      this.$router.push({ name: 'PropertyEdit', params })
    },
    /**
     * Navigate user to the CompanyOverview component.
     *
     * @param {Object} row
     */
    goToCompanyOverview (row) {
      this.$router.push({ name: 'CompanyOverview', params: { cid: row.company.id } })
    }
  }
}
</script>

<style scoped>
.date-picker {
  text-align: right;
}
.order-select {
  margin-left: 10px;
  width: 140px;
}
.link {
  cursor: pointer;
  display: inline-block;
  word-break: normal;
}
.link:hover {
  text-decoration: underline;
}
</style>
